@import 'mixins';
@import 'variables';

/** HEADINGS */
h1 {
    font-family: $font-bitterbrush-regular;
    font-size: 56px;
    line-height: 64px;
    margin-bottom: $margin-bottom;
    text-transform: uppercase;

    // @media (max-width: $screen-lg) {
    //     font-size: 40px;
    //     line-height: 52px;
    // }

    // @media (max-width: $screen-md) {
    //     font-size: 32px;
    //     line-height: 40px;
    // }

    // @media (max-width: $screen-sm) {
    //     font-size: 28px;
    //     line-height: 36px;
    // }
    @media (max-width: $screen-lg) {
        font-size: 40px;
        line-height: 52px;
    }

    // @media (max-width: $screen-md) {
    //     font-size: 32px;
    //     line-height: 40px;
    // }

    // @media (max-width: $screen-sm) {
    //     font-size: 28px;
    //     line-height: 36px;
    // }
}

h2 {
    @include h2;
}

h3, .h3 {
    @include h3;
}

h4, .h4 {
    font-family: $font-quicksand-variable;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: $margin-bottom;
    font-weight: bold;
}

h5, .h5 {
    font-family: $font-quicksand-variable;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: $margin-bottom;
    font-weight: bold;
}

/** TEXT */
.preamble {
    @include font-standard;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: $margin-bottom;
}

p,
.text-normal {
    @include font-standard;
    margin-bottom: $margin-bottom;
}

.text-large {
    @include font-standard;
    margin-bottom: $margin-bottom;
    font-size: 18px;
}

.textSmall {
    @include font-standard;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: $margin-bottom;
}

.description {
    @include font-standard;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: $margin-bottom;
}

.font-color-green {
    color: $primary-green-400;
}

.font-color-white {
    color: $accent-white;
}

.highlight-dark-green {
    font-weight:bold;
    color: $primary-green-500;
}

.font-warning {
    color: $secondary-orange-500;
}
@media  (max-width: $screen-sm), (min-width: $screen-md) {
    .payment-details {
        
        &.grid-cols-3 {
            grid-template-columns: auto auto auto;

            :last-child {
                margin-top:8px;
            }
        }
        .text-small,
        .textSmall {
            font-size: 0.8rem;
            margin-bottom:0;
        }
    }
}
@media  (min-width: $screen-md) {
    .payment-details {
        
        &.grid-cols-3 {
            column-gap: 5px;
        }
    }
}
@media (max-width: $screen-sm) {

    .mobile-txt-cols {
        .grid-cols-4 {
            grid-template-columns: auto auto auto auto;

            *:not(label) {
                font-size: 0.8rem;
            }
        }
    }
    .user-details {

        p {
            margin-bottom: 0;
        }
    }
}