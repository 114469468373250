.richtext {

  /** BUTTONS UMBRACO start */
  .btn_umbraco a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
  }

  .btn_umbraco_chevron-right a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;

    &:after {
      content: '';
      @include btn-chevron-right;
    }
  }

  .btn_umbraco_black a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-black;
  }

  .btn_umbraco_black_chevron-left a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-black;

    &:before {
      content: '';
      @include btn-chevron-left;
    }
  }

  .btn_umbraco_black_chevron-right a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-black;

    &:after {
      content: '';
      @include btn-chevron-right;
    }
  }

  .btn_umbraco_white a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-white;
  }

  .btn_umbraco_white_chevron-left a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-white;

    &:before {
      content: '';
      @include btn-chevron-left;
      filter: invert(1);
    }
  }

  .btn_umbraco_white_chevron-right a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-white;

    &:after {
      content: '';
      @include btn-chevron-right;
      filter: invert(1);
    }
  }

  .btn_umbraco_dark-contrast a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-dark-contrast;
  }

  .btn_umbraco_dark-contrast_chevron-left a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-dark-contrast;

    &:before {
      content: '';
      @include btn-chevron-left;
      filter: invert(1);
    }
  }

  .btn_umbraco_dark-contrast_chevron-right a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-dark-contrast;

    &:after {
      content: '';
      @include btn-chevron-right;
    }
  }

  .btn_umbraco_light-contrast a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-light-contrast;
  }

  .btn_umbraco_light-contrast_chevron-left a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-light-contrast;

    &:before {
      content: '';
      @include btn-chevron-left;
      filter: invert(1);
    }
  }

  .btn_umbraco_light-contrast_chevron-right a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-light-contrast;

    &:after {
      content: '';
      @include btn-chevron-right;
      filter: invert(1);
    }
  }

  .btn_umbraco_small a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-small;
  }

  .btn_umbraco_small_chevron-left a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-small;

    &:before {
      content: '';
      @include btn-chevron-left;
    }
  }

  .btn_umbraco_small_chevron-right a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-small;

    &:after {
      content: '';
      @include btn-chevron-right;
    }
  }

  .btn_umbraco_small_black a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-small;
    @include btn-black;
  }

  .btn_umbraco_small_black_chevron-left a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-small;
    @include btn-black;

    &:before {
      content: '';
      @include btn-chevron-left;
    }
  }

  .btn_umbraco_small_black_chevron-right a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-small;
    @include btn-black;

    &:after {
      content: '';
      @include btn-chevron-right;
    }
  }

  .btn_umbraco_small_white a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-small;
    @include btn-white;
  }

  .btn_umbraco_small_white_chevron-left a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-small;
    @include btn-white;

    &:before {
      content: '';
      @include btn-chevron-left;
    }
  }

  .btn_umbraco_small_white_chevron-right a {
    @include btn-base;
    @include btn-hover;
    @include btn-focus;
    @include btn-active;
    @include btn-disabled;
    @include btn-small;
    @include btn-white;

    &:after {
      content: '';
      @include btn-chevron-right;
    }


  }
  p:last-child {
    margin-bottom: 0;
  }

  blockquote {
    border-radius: 1.5rem;
    background-color: #ECECEC;
    padding: 2.5rem;
    font-family: "Quicksand Variable", sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: #181818;
    margin-bottom: 16px;
  }

  a {
    text-decoration: underline;
    font-weight: 500;

    &:hover {
      text-decoration: none;
    }
  }

  figure {
    img {
      width: 100%;
      height: auto;
    }

    figcaption {
      @include font-standard;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: $margin-bottom;
    }
  }

  .image-full-width {
    img {
      width: 100%;
      height: auto;
    }
  }

  .link-standalone a {
    @include standalone-link;
    text-decoration: underline;
    min-height: 32px;
    display: flex;
    align-items: center;
    padding: $outside-vertical-padding $outside-horisontal-padding;
    color: $secondary-black-400;
    position: relative;
    // padding-left: 36px;
    min-height: 32px;
    padding-left: calc($chevron-width + $chevron-padding + $outside-horisontal-padding);
    padding-right: $outside-horisontal-padding;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: $chevron-width;
      height: 24px;
      border-radius: 4px;
      background-size: 16px 16px;
      background-position: center;
      background-repeat: no-repeat;
      left: $outside-horisontal-padding;
      background-color: $primary-green-400 !important;
      background-image: url('/assets/img/icons/chevron.svg');
    }

    &:hover::before {
      background-color: $primary-green-300 !important;
    }

    &:focus::before {
      background-color: $primary-green-400 !important;
    }

    &:active::before {
      background-color: $primary-green-500 !important;
    }

    &:disabled::before,
    &[aria-disabled="true"]::before {
      opacity: 0.6;
    }

    &:focus {
      outline: 2px solid $interactive-blue;
      outline-offset: 2px;
      border-radius: 2px;
    }
  }

  /** BUTTONS UMBRACO end */

  /**umb_name:Text normal*/
  p,
  .text-normal {
    font-family: $font-quicksand-variable;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 16px;
  }

  /**umb_name:Preamble*/
  .preamble {
    font-family: $font-quicksand-variable;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  /**umb_name:Text small*/
  .textSmall {
    font-family: $font-quicksand-variable;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  /**umb_name:Description*/
  .description {
    font-family: $font-quicksand-variable;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  // /**umb_name:Heading 1*/
  // h1 {
  //   font-family: $font-bitterbrush-regular;
  //   font-size: 56px;
  //   line-height: 64px;
  //   margin-bottom: 16px;
  // }

  /**umb_name:Heading 2*/
  h2 {
    font-family: $font-quicksand-variable;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
  }

  /**umb_name:Heading 3*/
  h3 {
    font-family: $font-quicksand-variable;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  /**umb_name:Heading 4*/
  h4 {
    font-family: $font-quicksand-variable;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 16px;
  }

  /**umb_name:Heading 5*/
  h5 {
    font-family: $font-quicksand-variable;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 16px;
  }

  /**umb_name:Button standard*/
  .btnStandard {
    font-family: $font-quicksand-variable;
    font-size: 14px;
    line-height: 18px;
  }

  /**umb_name:Button small*/
  .btnSmall {
    font-family: $font-quicksand-variable;
    font-size: 12px;
    line-height: 16px;
  }

  /**umb_name:Standalone link*/
  .link {
    font-family: $font-quicksand-variable;
    font-size: 12px;
    line-height: 16px;
  }

  ol,
  ul {
    @include font-standard;
    list-style-position: outside;
    margin: 1em 0;
    padding-left: 40px;
  }

  ol {
    list-style-type: decimal;
    /* For ol */
  }

  ul {
    list-style-type: disc;
    /* For ul */
  }

  ol li,
  ul li {
    list-style-type: inherit;
    /* Ensure li inherits the list style from ol or ul */
    margin: 0.5em 0;
  }
}