/* Bitterbrush Fonts */
@font-face {
    font-family: 'Bitterbrush Regular';
    src: url('/assets/fonts/Bitterbrush_Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Bitterbrush';
    src: url('/assets/fonts/bitterbrush.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  /* Quicksand Fonts */
  @font-face {
    font-family: 'Quicksand Variable';
    src: url('/assets/fonts/Quicksand-VariableFont_wght.ttf') format('truetype');
    font-weight: 300 700;
    /* Specifies that this font supports weights from 100 to 900 */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Quicksand Bold';
    src: url('/assets/fonts/Quicksand-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Quicksand Light';
    src: url('/assets/fonts/Quicksand-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Quicksand Medium';
    src: url('/assets/fonts/Quicksand-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Quicksand Regular';
    src: url('/assets/fonts/Quicksand-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Quicksand SemiBold';
    src: url('/assets/fonts/Quicksand-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'OpenSans Regular';
    src: url('/assets/fonts/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
    font-weight: 300 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'OpenSans Italic';
    src: url('/assets/fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf') format('truetype');
    font-weight: 300 700;
    font-style: italic;
  }