.gm-style-iw {
    border-radius: 1.5rem !important;
    padding: 0 20px 20px 20px;

    .gm-style-iw-chr {
        position: relative;

        .gm-style-iw-ch {}

        button {
            position: absolute !important;
            right: 0;
            top: 0;
        }
    }

    .gm-style-iw-d {
        padding: 0 10px 10px 10px;

        .link {
            margin-top: 10px;
        }
    }
}