@keyframes slideDown {
    from {
      max-height: 0;
      opacity: 0;
      transform: translateY(-20px); /* Start from 20px above */
    }
    to {
      max-height: 1000px; /* Adjust as needed */
      opacity: 1;
      transform: translateY(0); /* End at the original position */
    }
  }
  
  @keyframes slideUp {
    from {
      max-height: 1000px; /* Adjust as needed */
      opacity: 1;
      transform: translateY(0); /* Start at the original position */
    }
    to {
      max-height: 0;
      opacity: 0;
      transform: translateY(-20px); /* End 20px above */
    }
  }
  
  .slide-down {
    animation: slideDown 0.3s ease-out forwards;
  }
  
  .slide-up {
    animation: slideUp 0.3s ease-out forwards;
  }