/** temporary area border top **/
.bt-temp {
    &:not(:first-of-type) {
        border-top: 1px solid #1e1e1e;
        padding-top: 1rem;
    }
}

.mb-0-i {
    margin-bottom: 0 !important;
}

.mb-1-i {
    margin-bottom: 0.25rem !important;
}

.mb-2-i {
    margin-bottom: 0.5rem !important;
}

.mb-3-i {
    margin-bottom: 0.75rem !important;
}

.mb-4-i {
    margin-bottom: 1rem !important;
}

.mb-5-i {
    margin-bottom: 1.25rem !important;
}

.mb-6-i {
    margin-bottom: 1.5rem !important;
}

.mb-7-i {
    margin-bottom: 1.75rem !important;
}

.mb-8-i {
    margin-bottom: 2rem !important;
}

@media (max-width: $screen-lg) {
    .mb-0-i-mobile {
        margin-bottom: 0 !important;
    }

    .mb-1-i-mobile {
        margin-bottom: 0.25rem !important;
    }

    .mb-2-i-mobile {
        margin-bottom: 0.5rem !important;
    }

    .mb-3-i-mobile {
        margin-bottom: 0.75rem !important;
    }

    .mb-4-i-mobile {
        margin-bottom: 1rem !important;
    }

    .mb-5-i-mobile {
        margin-bottom: 1.25rem !important;
    }

    .mb-6-i-mobile {
        margin-bottom: 1.5rem !important;
    }

    .mb-7-i-mobile {
        margin-bottom: 1.75rem !important;
    }

    .mb-8-i-mobile {
        margin-bottom: 2rem !important;
    }
}

.align-center {
    align-items: center;
}

.flex-important {
    display: flex !important;
}

.inline-important {
    display: inline !important;
}

.no-underline {
    text-decoration: none !important;
}

.nowrap {
    white-space: nowrap;
}

.loader {
    border: 10px solid $primary-green-100;
    border-radius: 50%;
    border-top: 10px solid $primary-green-400;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    z-index: 1000;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.no-gap {
    margin: 0 !important;
}

@media (max-width: $screen-lg) {
    .no-gap-mobile {
        margin: 0 !important;
    }
}

.green-background-mobile {
    @media (max-width: $screen-lg) {
        background-color: $primary-green-200;
    }
}

.hidden-i {
    display: none !important;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.smallSpinner {
    width: 20px;
    height: 20px;
    border: 4px solid $primary-green-400;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: auto;
}