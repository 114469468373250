@import 'variables.scss';
@import 'fonts.scss';
@import 'mixins.scss';

label[for] {
    @include font-small-bold;
    width: 100%;
    display: inline-block;
    margin-bottom: 10px;
}

input[type="text"]:not(.non-standard-input),
input[type="tel"]:not(.non-standard-input),
input[type="telephone"]:not(.non-standard-input),
input[type="email"]:not(.non-standard-input),
.react-tel-input input,
.phone-input {
    @include font-standard;
    width: 100%;
    height: 56px;
    border-radius: 8px;
    border: 1px solid $border-grey;
    padding: 15px;

    &:hover {
        border-color: $primary-green-400;
    }

    &:focus {
        outline: 2px solid $interactive-blue;
        outline-offset: 2px;
        border-color: $primary-green-400;
    }

    &:active {
        border: 2px solid $primary-green-400;
    }

    &:disabled,
    [aria-disabled="true"] {
        background-color: $accent-grey;
        border: none;
    }

    &.validation-error {
        border-color: $interactive-red;
        color: $interactive-red;
    }
}

.react-tel-input {
    input {
        width: 100% !important;
        padding: 15px 15px 15px 58px !important;
        border-radius: 8px !important;

        &:focus {
            background-color: #fff;
            // border-color: #80bdff;
            box-shadow: none !important;
            outline: 2px solid $interactive-blue !important;
            outline-offset: 2px;
            border-color: $primary-green-400 !important;
        }

        &.validation-error {
            border-color: $interactive-red !important;
            color: $interactive-red !important;
        }
    }
}

.border-green {
    border-color: $primary-green-400 !important;
}

.select-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;

    input[type="text"],
    input[type="telephone"],
    input[type="email"] {
        width: 100%;
        padding: 10px;
        border: 1px solid $border-grey;
        border-radius: 8px;
        background-color: white;
        cursor: pointer;
    }

    &.open {

        input[type="text"],
        input[type="telephone"],
        input[type="email"] {
            &:focus {
                outline: none;
            }
        }
    }

    .arrow {
        position: absolute;
        right: 10px;
        top: calc(50% + (32px / 2) - (12px / 2));
        width: 16px;
        height: 16px;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer; // Make the arrow icon clickable
    }

    .arrow-alt {
        position: absolute;
        right: 10px;
        top: calc(50% + (0px) - (12px / 2));
        width: 16px;
        height: 16px;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer; // Make the arrow icon clickable
    }

    .options-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        padding: 20px 20px 20px 0px;
        max-height: 240px;
        overflow: hidden;
        z-index: 10;
        background-color: #ffffff;
        border-radius: 0px 0px 8px 8px;
        top: 80px;
        border: 1px solid $border-grey;
        border-top: none;

        &:hover {
            border-color: $primary-green-400;
        }

        &.adjusted-top {
            top: calc(50% + 30px - 6px) !important;
        }


        .options {
            left: 0;
            right: 0;
            max-height: 200px;
            overflow-y: auto;
            background-color: white;
            z-index: 11;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $primary-green-400;
                border-radius: 8px;
            }

            &::-webkit-scrollbar-track {
                background-color: $primary-green-200;
            }

            .option {
                padding: 10px;
                cursor: pointer;
                display: flex;
                align-items: center;

                &:hover {
                    background-color: $primary-green-200;
                }

                &.focused {
                    background-color: $primary-green-200;

                }

                .checkbox {
                    @include checkbox-default();

                    &.selected {
                        @include checkbox-default-checked();
                    }
                }
            }
        }
    }

    &.validation-error {
        label {
            color: $interactive-red;
        }

        input[type="text"] {
            border-color: $interactive-red;
            color: $interactive-red;
        }

        .arrow {
            top: calc(50% - 1px);
        }
    }
}

// --- CHECKBOXES ---

.checkbox-default {
    @include checkbox-styles();
}

.checkbox-white {
    @include checkbox-styles-white();
}

.checkbox-default,
.checkbox-white {
    &.validation-error {
        .custom-checkbox {
            border-color: $interactive-red;
        }

        .checkbox-content {
            color: $interactive-red;

            a {
                color: $interactive-red;
            }
        }
    }
}

// --- RADIO BUTTONS ---

.radiobutton-default {
    @include radiobutton-styles();
}

.radiobutton-white {
    @include radiobutton-styles-white();
}

.radiobutton-default,
.radiobutton-white {
    &.validation-error {
        .custom-radiobutton {
            border-color: $interactive-red;
        }

        .radiobutton-content {
            color: $interactive-red;

            a {
                color: $interactive-red;
            }
        }
    }
}

.helper-text {
    @include font-description-standard;
    width: 100%;
    display: inline-block;
    color: $secondary-black-400;
    margin-top: 5px;
    padding-left:16px;
}

span[role="alert"] {
    @include validation-error-helper-text;
}