@import 'variables.scss';
@import 'mixins.scss';

// $outside-vertical-padding: 5px;
// $outside-horisontal-padding: 10px;
// $chevron-padding: 8px;
// $chevron-width: 24px;

.link {
    @include standalone-link;
    text-decoration: underline;



    &.standalone {
        min-height: 32px;
        display: flex;
        align-items: center;
        padding: $outside-vertical-padding $outside-horisontal-padding;

        &:focus {
            outline: 2px solid $interactive-blue;
            outline-offset: 2px;
            border-radius: 2px;
        }
    }

    &.text-color-black {
        color: $secondary-black-400;
    }

    &.text-color-white {
        color: $accent-white;
    }

    &.chevron {
        position: relative;
        // padding-left: 36px;
        min-height: 32px;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: $chevron-width;
            height: 24px;
            border-radius: 4px;
            background-size: 16px 16px;
            background-position: center;
            background-repeat: no-repeat;
        }

        &-left {
            padding-left: calc($chevron-width + $chevron-padding + $outside-horisontal-padding);
            padding-right: $outside-horisontal-padding;

            &::before {
                left: $outside-horisontal-padding;
            }
        }

        &-right {
            padding-right: calc($chevron-width + $chevron-padding + $outside-horisontal-padding);
            padding-left: $outside-horisontal-padding;

            &::before {
                left: auto;
                right: $outside-horisontal-padding;
            }
        }

        &-color-green {
            &::before {
                background-color: $primary-green-400 !important;
                background-image: url('/assets/img/icons/chevron.svg');
            }

            &:hover::before {
                background-color: $primary-green-300 !important;
            }

            &:focus::before {
                background-color: $primary-green-400 !important;
            }

            &:active::before {
                background-color: $primary-green-500 !important;
            }

            &:disabled::before,
            &[aria-disabled="true"]::before {
                opacity: 0.6;
            }
        }

        &-color-black {
            &::before {
                background-color: $secondary-black-400 !important;
                background-image: url('/assets/img/icons/chevron.svg');
            }

            &:hover::before {
                background-color: $secondary-black-300 !important;
            }

            &:focus::before {
                background-color: $secondary-black-400 !important;
            }

            &:active::before {
                background-color: $secondary-black-500 !important;
            }

            &:disabled::before,
            &[aria-disabled="true"]::before {
                opacity: 0.6;
            }
        }

        &-color-white {
            &::before {
                background-color: $accent-white !important;
                background-image: url('/assets/img/icons/chevron-black.svg');
            }

            &:hover::before {
                background-color: $accent-grey !important;
            }

            &:focus::before {
                background-color: $accent-white !important;
            }

            &:active::before {
                background-color: $accent-grey !important;
            }

            &:disabled::before,
            &[aria-disabled="true"]::before {
                opacity: 0.6;
            }
        }
    }

    &.link-size-small {
        font-size: 10px;
    }

    &.link.size-standard {
        font-size: 12px;
    }

    &.link-size-medium {
        font-size: 14px;
    }

    &.link-size-large {
        font-size: 16px;
    }

    &.no-underline {
        text-decoration: none !important;
    }

    .no-underline {
        text-decoration: none !important;
    }

}