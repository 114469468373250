.footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;

  a {
    text-decoration: none;
  }
}

.section {
  border-bottom: 1px solid rgb(var(--color-white));
  width: 100%;

  li {
    margin-top: 16px;
  }
}
