@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
@import 'styles/common/fonts.scss';
@import 'styles/common/variables.scss';
@import 'styles/common/mixins.scss';
@import 'styles/common/forms.scss';
@import 'styles/common/umbraco_richText.scss';
@import 'styles/common/links.scss';
@import 'styles/common/text.scss';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import 'styles/common/googleMaps.scss';
@import 'styles/common/footer.scss';
@import 'styles/common/animations.scss';
@import 'styles/common/utility.scss';



@layer base {
  :root {
    --body-font: "Quicksand", sans-serif;
    --body-font-size: 16px;

    --color-green-500: 70 131 37;
    --color-green-400: 108 200 59;
    --color-green-300: 148 219 110;

    --color-orange-500: 214 137 26;
    --color-orange-400: 247 158 28;
    --color-orange-300: 249 198 122;

    --color-black-500: 0 0 0;
    --color-black-400: 24 24 24;
    --color-black-300: 102 102 102;

    --color-grey: 236 236 236;
    --color-white: 255 255 255;

    --color-blue: 24 33 251;
    --color-red: 255 62 50;
    --color-lime: 211 239 196;
  }
}

@mixin button-variant($background, $border, $color) {
  background-color: $background;
  border: 0;
  color: $color;

  &:hover {
    background-color: var(--color-green-300);
  }

  &:focus {
    border: 2px solid var(--color-blue);
  }

  &:active {
    background-color: var(--color-green-500);
  }

  &:disabled {
    background: rgba($background, 0.5);
    border: 2px solid $background;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
}

html,
body {
  max-width: 100vw;
  // overflow-x: hidden;
}

body {
  font-family: var(--body-font);
  font-size: var(--body-font-size);
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 5rem;

  .visible-important-message {
    margin-top:5rem;
  }

  &.no-header {
    margin-top:0;
  }
}

.center-text {
  text-align: center;
}

a {
  color: inherit;
}

.text-white {
  color: #fff;
}

.bg-linear-green {
  background: linear-gradient(0deg,
      rgba(108, 200, 59, 0.3) 0%,
      rgba(108, 200, 59, 0.3) 100%),
    #fff;
}

.bg-linear-green-alt {
  background: linear-gradient(0deg,
      rgba(108, 200, 59, 0.6),
      rgba(108, 200, 59, 0.6)),
    #ffffff;
}



.block {
  display: inline-block;
}

.padding {
  padding: 5rem;
}

.padding-vertical {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.padding-horizontal {
  padding-left: 5rem;
  padding-right: 5rem;
}

.image-container {
  max-width: 34rem;

  >div {
    position: unset !important;
  }

  img {
    object-fit: contain;
    position: relative !important;
  }
}

.news-type {
  font-size: 12px;
  padding: 0px 5px;
  height: 22px;
  border-radius: 8px;
  text-align: center;
  line-height: 22px;
  margin-right: 8px;
}

.text-5xl {
  font-size: 70px;
  line-height: 78px;
}




/** NEW CSS **/

.menu-link {
  @include font-standard;
  font-size: 14px;
  font-weight:bold;
  color: $accent-white;
  line-height: 18px;
}



/** BUTTONS START */

/** BUTTONS FRONTEND start */
.btn {
  @include btn-base;
  @include btn-hover;
  @include btn-focus;
  @include btn-active;
  @include btn-disabled;

  &.less-x-padding {
    padding: 16px 10px;
  }


  &.small {
    @include btn-small;
  }

  &.chevron-left {
    @include btn-chevron-left;
  }

  &.chevron-right {
    @include btn-chevron-right;
  }

  &.btn-w100p {
    @include btn-base-w100p;

    &.chevron-left {
      @include btn-chevron-left-w100p;
    }

    &.chevron-right {
      @include btn-chevron-right-w100p;
    }
  }

  &.black {
    @include btn-black;
  }

  &.white {
    @include btn-white;
  }

  &.dark-contrast {
    @include btn-dark-contrast;
  }

  &.light-contrast {
    @include btn-light-contrast;
  }
}

/** BUTTONS FRONTEND end */

/** BUTTONS END */


.bg-see-through {
  background-color: unset;
}

.bg-white {
  background-color: #fff;
}

.bg-light-gs-green {
  background-color: $primary-green-50;
}

.bg-medium-gs-green {
  background-color: $primary-green-200;
}

@media (max-width: $screen-lg) {
  .bg-medium-gs-green-mobile {
    background-color: $primary-green-200;
  }
}

.bg-gs-green {
  background-color: $primary-green-400;
}

.bg-gs-orange {
  background-color: $secondary-orange-400;
}

.bg-gs-green-50 {
  background-color: $primary-green-50;
}

.bg-gs-orange {
  background-color: $secondary-orange-400;
}

.bg-gs-light-blue {
  background-color: $accent-light-blue;
}

.full-width {
  width: 100%;
}

.three-forths-width {
  width: 75%;
}

.two-thirds-width {
  width: 66.666667%;
}

.half-width {
  width: 50%;
}

.one-third-width {
  width: 33.333333%;
}

.one-forths-width {
  width: 25%;
}

.w-500-responsive {
  width: 500px;

  @media (max-width: $screen-sm) {
    width: 100%;
  }
}

.w-600-responsive {
  width: 600px;

  @media (max-width: $screen-sm) {
    width: 100%;
  }
}

@for $i from 1 through 10 {
  .truncate-multiline-#{$i} {
    @include truncate-multiline($i);
  }
}

.card {
  @media (max-width: $screen-md) {
    &.card-image-only {

      padding: 0;
      height: auto;
    }
  }
}

.card-item,
.site-items {
  position: relative;
  overflow: hidden;


  a:not(.area-heading),
  .disabled-item {
    display: inline-block;
    text-decoration: none;
    background-color: $primary-green-50;



    .discount-area {
      background-color: $secondary-orange-400;
      color: $accent-white;
      text-align: center;
      font-weight: bold;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
      line-height: normal;
      padding: 1rem 2rem;
    }

    .no-discount-area {
      background-color: $primary-green-400;
      color: $accent-white;
      text-align: center;
      font-weight: bold;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
      line-height: normal;
      padding: 1rem 2rem;
      margin-bottom: 32px;
    }
  }

  a:not(.area-heading) {
    &:hover {
      background-color: $primary-green-100;
    }

    &:focus {
      outline: 2px solid $interactive-green;
    }

    &:active {
      background-color: $primary-green-200;
    }
  }

  .disabled-item {
    background-color: $secondary-black-50;

    .discount-area {
      background-color: $secondary-black-100;
      box-shadow:none;
      text-shadow: none;
      color: $accent-white;
    }

    .no-discount-area {
      background-color: $secondary-black-100;
      color: $accent-white;
      box-shadow:none;
      text-shadow: none;
    }
  }
}

.chip {
  @include font-standard;
  font-size: 14px;
  text-transform: uppercase;
  background-color: $secondary-orange-400;
  padding: 4px 8px;
  border-radius: 8px;
  color: $accent-white;
  display: inline-block;
  font-weight: bold;
  line-height: normal;
}

.empty-error-container {
  margin-top: -5rem;
}